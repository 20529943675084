<template>
  <div class="companyDetails">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.positiveNegativeInformation.inputs.negative') }}</label>
          <Dropdown id="positive-info-type"
                    v-model="model.negativeInfo.type"
                    class="co-input-marketing p-multiselect"
                    :options="negativeTypes"
                    option-label="name"
                    option-value="value"
                    :placeholder="t('multiselect.placeholder')" />
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.positiveNegativeInformation.inputs.date') }}</label>
          <div class="row m-initial w-100">
            <div class="col-6 min-max">
              <Calendar id="negative-info-min"
                        v-model="model.negativeInfo.start"
                        date-format="yy.mm.dd"
                        :placeholder="t('marketing.companyDetails.inputs.min')" />
            </div>
            <div class="col-6 min-max">
              <Calendar id="negative-info-max"
                        v-model="model.negativeInfo.end"
                        date-format="yy.mm.dd"
                        :placeholder="t('marketing.companyDetails.inputs.max')" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.positiveNegativeInformation.inputs.positive') }}</label>
          <Dropdown id="positive-info-type"
                    v-model="model.positiveInfo.type"
                    class="co-input-marketing p-multiselect"
                    :options="positiveTypes"
                    option-label="name"
                    option-value="value"
                    :placeholder="t('multiselect.placeholder')" />
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.positiveNegativeInformation.inputs.date') }}</label>
          <div class="row m-initial w-100">
            <div class="col-6 min-max">
              <Calendar id="negative-info-min"
                        v-model="model.positiveInfo.start"
                        date-format="yy.mm.dd"
                        :placeholder="t('marketing.companyDetails.inputs.min')" />
            </div>
            <div class="col-6 min-max">
              <Calendar id="negative-info-max"
                        v-model="model.positiveInfo.end"
                        date-format="yy.mm.dd"
                        :placeholder="t('marketing.companyDetails.inputs.max')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const model = defineModel() as any;
  import { t } from "@/logic";
  import { computed } from "vue";

  const props = defineProps(["negativeInfoTypes", "positiveInfoTypes"]);
  const positiveTypes = computed(() => {
    return props.positiveInfoTypes ?
      Object.entries(props.positiveInfoTypes).map(([key, value]) => {
        return { value: key, name: value || "" };
      }) 
      : [];
  });
  const negativeTypes = computed(() => {
    return props.negativeInfoTypes ?
      Object.entries(props.negativeInfoTypes).map(([key, value]) => {
        return { value: key, name: value || "" };
      }) 
      : [];
  });
</script>

<style scoped lang="scss">
.m-initial {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
</style>
