<template>
  <SearchPage :icon="(props.type == 'marketing' ? 'marketing-search' : 'search-detailed')"
    :title="t((props.type == 'marketing' ? 'marketing' : 'detailedSearch') + '.title')">
    <template #content>
      <div class="page-main-marketing pt-5" @keyup.enter="onSearch">
        <div class="container-fluid">
          <div class="d-flex flex-column gap-5">
            <Card class="p-4">
              <template #header>
                <i class="icon company-report pi" />
                <span class="title">{{ t('marketing.cards.basicDetails.title') }}</span>
              </template>
              <template #content>
                <SearchDetails v-model="params.companyDetails" :prefill="prefill" />
              </template>
            </Card>

            <Card id="address-details-card" class="p-4">
              <template #header>
                <i class="icon company-report pi" />
                <span class="title">{{ t('marketing.cards.addressData.title') }}</span>
              </template>
              <template #content>
                <AddressDetails v-model="params.companyDetails" :prefill="prefill" :addressTypes="addressTypes"/>
              </template>
            </Card>

            <Card id="member-details-card" class="p-4">
              <template #header>
                <i class="icon company-report pi" />
                <span class="title">{{ t('marketing.cards.personalData.title') }}</span>
              </template>
              <template #content>
                <MemberDetails v-model="params.companyDetails" :memberTypes="memberTypes"/>
              </template>
            </Card>

            <Card class="p-4">
              <template #header>
                <i class="icon financial-reports pi" />
                <span class="title">{{ t('marketing.cards.financialDetails.title') }}</span>
              </template>
              <template #content>
                <FinancialSearch v-model="params.financialDetails" :prefill="prefill" />
              </template>
            </Card>

            <Card class="p-4">
                <template #header>
                  <i class="icon negative-info pi" />
                  <span class="title">{{ t('marketing.cards.positiveNegativeInformation.title') }}</span>
                </template>
                <template #content>
                  <PositiveNegativeInformationSearch v-model="params" 
                                                     :negative-info-types="prefill.negativeInfoTypes"
                                                     :positive-info-types="prefill.positiveInfoTypes" />
                </template>
              </Card>

            <Card v-if="type == 'marketing'" class="p-4">
              <template #header>
                <i class="icon activity-scope pi" />
                <span class="title">{{ t('marketing.cards.activityScope.title') }}</span>
              </template>
              <template #content>
                <div class="text-end d-none d-md-block" style="z-index: 900">
                  <span style="line-height: 2.5rem">
                    <Checkbox id="inAllActivity" v-model="params.inAllActivity" :binary="true" />
                    <label for="inAllActivity">
                      {{ t('activities.inAllActivity') }}
                    </label>
                  </span>
                  <span class="mx-4" style="line-height: 2.5rem">
                    <Checkbox id="inAllActivity" v-model="params.showOnlySelected" :binary="true" />
                    <label for="inAllActivity">
                      {{ t('activities.showSelected') }}
                    </label>
                  </span>
                  <Button :label="t('activities.deleteSelected')" class="mx-3" @click="clearSelectedActivities" />
                </div>
                <Tree v-model:selectionKeys="selectedActivities" id="activities" :value="activities" selection-mode="checkbox"
                  :filter="true" :filter-placeholder="t('activities.searchActivities')" scroll-height="300px"  />
              </template>
            </Card>
            <div class="search-field my-5">
              <div class="d-flex justify-content-end align-items-center">
                <div class="co-checkbox-primary-marketing" style="margin-right: 1rem">
                  <Checkbox id="onlyValid" v-model="params.onlyValid" :binary="true" />
                  <label for="onlyValid">
                    {{ t('marketing.checkboxes.onlyValid') }}
                  </label>
                </div>
                <Button :label="t('marketing.buttons.delete')" class="mx-3 d-none d-md-block button-red"
                  @click="onClear" />
                <Button :label="t('marketing.buttons.search')" @click="onSearch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SearchPage>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed, watch } from "vue";
  import { DetailedSearchForm, DetailedSearchFormQuery } from "@/types/DetailedSearchForm";
  import type { Ref } from 'vue';
  import { http, t, route, router, init } from "@/logic";
  import moment from 'moment';

  init();

  const props = defineProps<{
    type: string
  }>();

  const addressTypes = [
    { id: "1", value: t("marketing.companyDetails.inputs.hq") },
    { id: "2", value: t("marketing.companyDetails.inputs.site") },
    { id: "3", value: t("marketing.companyDetails.inputs.branch") },
  ];

  const memberTypes = [
    { id: "1", value: t("marketing.memberDetails.inputs.ceo") },
    { id: "2", value: t("marketing.memberDetails.inputs.member") },
    { id: "3", value: t("marketing.memberDetails.inputs.auditor") },
  ];

  const getInitialForm = () => <DetailedSearchForm>({
    positiveInfo: {
      type: "",
      start: null,
      end: null,
    },
    negativeInfo: {
      type: "",
      start: null,
      end: null,
    },
    companyDetails: {
      companyName: "",
      taxNumber: "",
      companyNumber: "",
      bankAccountNumber: "",
      address: "",
      zip: null,
      city: "",
      street: "",
      employees: {
        min: null,
        max: null,
      },
      foundation: {
        min: null,
        max: null,
      },
      type: "",
      status: "",
      county: [],
      region: [],
      addressType: [],
      memberTaxNumber: "",
      personName: "",
      motherName: "",
      personZip: "",
      personCity: "",
      personStreet: "",
      personTaxId: "",
      memberType: [],
    },
    financialDetails: {
      fixedAssets: {
        min: null,
        max: null,
      },
      currentAssets: {
        min: null,
        max: null,
      },
      provisions: {
        min: null,
        max: null,
      },
      liabilities: {
        min: null,
        max: null,
      },
      shareholdersEquity: {
        min: null,
        max: null,
      },
      subscribedCapital: {
        min: null,
        max: null,
      },
      domesticSales: {
        min: null,
        max: null,
      },
      exportSales: {
        min: null,
        max: null,
      },
      totalSales: {
        min: null,
        max: null,
      },
      ebitda: {
        min: null,
        max: null,
      },
      accruedAndDeferredAssets: {
        min: null,
        max: null,
      },
      accruedAndDeferredLiabilities: {
        min: null,
        max: null,
      },
      incomeFromOperations: {
        min: null,
        max: null,
      },
      incomeBeforeTaxes: {
        min: null,
        max: null,
      },
      accountsPayable: {
        min: null,
        max: null,
      },
      corporateTax: {
        min: null,
        max: null,
      },
    },
    activities: [],
    onlyValid: false,
    inAllActivity: false,
    showOnlySelected: false,
  });

  const params: Ref<DetailedSearchForm> = ref(getInitialForm());

  const prefill = ref({} as any);
  const selectedActivities = ref({});
  const url = computed(() => {
    return props.type == "marketing" ? "/api/marketing/" : "/api/advanced-search/";
  });

  const activities = computed(() => {
    const keys = Object.keys(selectedActivities.value);
    const selected = keys
      ? keys.filter((x) => x.length == 4)
      : [];

    var filtered = prefill.value.activities2;

    if (filtered && params.value.showOnlySelected) {
      filtered = JSON.parse(JSON.stringify(filtered));

      filtered.forEach(first => {
        first.children.forEach(second => {
          second.children = second.children.filter((x) => selected.indexOf(x.key) > -1);
        });

        first.children = first.children.filter((x) => x.children.length > 0);
      });

      filtered = filtered.filter((x) => x.children.length > 0);
    }


    return filtered;
  });

  async function onSearch() {
    const query:DetailedSearchFormQuery = { 
      ...params.value,
      companyDetails: {
        ...params.value.companyDetails,
        addressType: params.value.companyDetails.addressType.map((x) => x.id),
        memberType: params.value.companyDetails.memberType.map((x) => x.id),
      },
      activities: Object.keys(selectedActivities.value),
     };
    const data = await http.post(url.value, {
      version: 1,
      query
    });
    data.value = data;
    await router.push({
      name: props.type + ".search.results",
      params: {
        id: data.id,
      },
    });
  }

  watch(props, () => {
    setDefaultValues();
  });

  function onClear() {
    params.value = getInitialForm();
    clearSelectedActivities();
  }

  function clearSelectedActivities() {
    selectedActivities.value = {};
  }

  async function setDefaultValues() {
    let query = "";

    if (route.params.id) {
      query = route.params.id as string;
    }

    const data = await http.get(url.value + query);
    if (route.params.id) {
      const values = data.params;
      if (values.companyDetails.addressType) {
        values.companyDetails.addressType = addressTypes.filter((x) => values.companyDetails.addressType.includes(x.id));
      }
      if (values.companyDetails.memberType) {
        values.companyDetails.memberType = memberTypes.filter((x) => values.companyDetails.memberType.includes(x.id));
      }
      if (values.companyDetails.foundation.min) {
        values.companyDetails.foundation.min = moment(String(values.companyDetails.foundation.min)).format('YYYY.MM.DD');
      }
      if (values.companyDetails.foundation.max) {
        values.companyDetails.foundation.max = moment(String(values.companyDetails.foundation.max)).format('YYYY.MM.DD');
      }
      if (values.positiveInfo.start) {
        values.positiveInfo.start = moment(String(values.positiveInfo.start)).format('YYYY.MM.DD');
      }
      if (values.positiveInfo.end) {
        values.positiveInfo.end = moment(String(values.positiveInfo.end)).format('YYYY.MM.DD');
      }
      if (values.negativeInfo.start) {
        values.negativeInfo.start = moment(String(values.negativeInfo.start)).format('YYYY.MM.DD');
      }
      if (values.negativeInfo.end) {
        values.negativeInfo.end = moment(String(values.negativeInfo.end)).format('YYYY.MM.DD');
      }
      params.value = values;

      if (data.params.activities) {
        selectedActivities.value = {};
      data.params.activities.forEach(x => {
        selectedActivities.value[x] = { checked: true, partialChecked: false };
      });
    }
    } else {
      params.value.companyDetails.addressType = addressTypes.filter((x) => {
          const defaultValues = props.type == 'marketing' 
            ? ["Headquarter"]
            : ["Headquarter", "Branch", "Site"];
          return defaultValues.includes(x.id);
        }),
      params.value.companyDetails.memberType = memberTypes.filter((x) => {
        const defaultValues = ["Signer", "Member"];
        return defaultValues.includes(x.id);
      })
    }
    prefill.value = data.prefill;
    params.value.onlyValid = props.type == "marketing";
  }

  onMounted(setDefaultValues);
</script>

<style lang="scss" scoped>
.page-main-marketing {
  max-width: 120rem;
  margin: 0 auto;

  & .search-field {
    & p {
      margin: 4rem 0 2rem;
      font-weight: 500;
      font-size: 1.4rem;
      text-align: center;
    }
  }
}

.tab-header {
  cursor: pointer;
}

.not-useful {
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: 1rem;
  color: #f3792b;
  font-size: 2rem;
}

.p-tree {
  margin-top: -40px;
}
</style>