<template>
  <div class="sidebar">
    <div v-if="!loading" ref="clickoutside" class="company-info">
      <div class="company-info-container">
        <div :class="{ 'active': isHamburgerActive, 'no-sidebar': $route.name === 'report.connections' }"
          class="hamburger-sidebar hamburger-sidebar--stack" @click="toggleHamburger">
          <div class="inner">
            <span class="bar" />
            <span class="bar" />
            <span class="bar" />
          </div>
        </div>
        <h2 class="company-name">
          {{ company.name }}
        </h2>
        <div class="company-status">
          <div class="icon" :class="company.status.icon.key" :style="{color: company.status.icon.color}" />
          <div class="status">
            <span>{{ company.status.value }}</span>
          </div>
        </div>
        <div class="company-rating">
          <div class="rating" :style="{backgroundColor: company.risk.backgroundColor}">
            {{ company.risk.value }}
          </div>
          <div class="status d-none d-lg-block">
            <span>{{ company.risk.label }}</span>
          </div>
        </div>
        <div v-if="company.type" class="company-status">
          <div class="icon" :class="company.type.icon" />
          <div class="status">
            <span>{{ company.type.name }}</span>
          </div>
        </div>
      </div>
      <div class="company-info-controls">
        <div v-if="showOnlyValid" class="co-checkbox-primary mr-1 d-none d-md-block">
          <Checkbox v-model="user.onlyValid" input-id="onlyValid" :binary="true" />
          <label for="onlyValid">
            <span class="d-none d-xl-inline">
              {{ t('companyData.checkboxes.onlyValidDetails') }}
            </span>
            <span class="d-xl-none">
              {{ t('companyData.checkboxes.onlyValidDetailsSmall') }}
            </span>
          </label>
        </div>

        <a class="icon-button download" :href="downloadUrl()" target="_blank">
          <i class="icon export" />
          <span class="label d-none d-lg-inline">{{ t('main.sidebar.download') }}</span>
        </a>

        <div class="icon-button" @click="toggleMonitoring">
          <span class="icon" :class="company.monitoring.icon.key" :style="{color: company.monitoring.icon.color}" />
          <span class="label d-none d-lg-inline">
            {{ company.monitoring.label }}
          </span>
        </div>

        <div class="d-flex monitoring-dropdown">
          <div class="icon-button">
            <span class="icon company-added" @click="addToComparison"/>
            <div class="dropdown b-dropdown dropdown-comparison btn-group">
              <button type="button"
                      class="btn btn-secondary"
                      @click="toggleComparison">
                <span class="label d-none d-lg-inline">
                  {{ t('summary.checkbox.comparison') }}
                </span>
              </button>
            </div>
          </div>
        </div>

        <OverlayPanel ref="op">
          <div v-if="comparisonCompanies.length">
            <div role="menu" 
                 class="comparison-dowpdown-content"
                 tabindex="-1" 
                 x-placement="bottom-start">
              <div v-for="(item, index) in comparisonCompanies"
                   :key="item.id"
                   role="presentation"
                   class="m-3">
                <a role="menuitem"
                   href="#"
                   target="_self"
                   class="dropdown-item">
                  <div class="d-flex align-items-center" 
                       style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                    <p class="text"
                       style="max-width: 13rem; overflow: ellipsis; white-space: nowrap; text-overflow: ellipsis;">{{ item.name }}</p>
                    <i class="icon company-deleted p-2" 
                       style="position: absolute; right: 0;" 
                       @click="removeFromComparison(index)" />
                  </div>
                </a>
              </div>
            </div>
            <div class="m-3 button button--primary button--small"
                 @click="$router.push({name: 'comparison'})">
              {{ t('main.sidebar.comparison.show') }}
            </div>
            <div class="m-3 button button--primary button--small"
                 @click="removeAll()">
              {{ t('main.sidebar.comparison.removeall') }}
            </div>
          </div>
          <div v-else class="comparison-dowpdown-content">
            <p class="text-center">{{ t('main.sidebar.comparison.empty') }}</p>
          </div>
        </OverlayPanel>
      </div>
    </div>
    <div>
      <div :class="{ 'show': isHamburgerActive, 'no-sidebar': $route.name === 'report.connections' }"
           class="sidebar-content">
        <router-link v-for="item in menuItems" :key="item.routeName"
                     :to="{name: item.routeName}"
                     :class="{'active': $route.name === item.routeName, [item.class]: true}"
                     @click="item.toggleHamburger ? toggleHamburger() : null">
          <div class="icon" :class="item.icon" :style="item.style"/>
          <span :style="item.style">{{ item.title }}</span>
        </router-link>
        <a v-if="company.menu.extraMenu" :href="company.menu.extraMenu.link" target="_blank">
          <div class="icon home-settings" />
          <span>{{ company.menu.extraMenu.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { http, t, toggle, toast, init, route, comparison, user } from "@/logic";
  import { onClickOutside, useEventBus } from "@vueuse/core";

  init();

  const loading = ref(false);
  const isHamburgerActive = ref(false);  
  const showOnlyValid = ref(false);  
  const company = reactive({
    id: "",
    name: "",
    tax8: "",
    positiveColor: "",
    negativeColor: "",
    menu: {} as any,
    status: {
      icon: {
        key: ""
      }
    } as any,
    risk: {} as any,
    type: {} as any,
    monitoring: {
      icon: {
        key: ""
      }
    } as any
  });
  const comparisonCompanies = ref(comparison.companies || []);
  const clickoutside = ref(null);
  const op = ref();
  const menuItems = computed(() => {
    return [
      {
        show: company.menu.summary,
        routeName: "report.summary",
        icon: "summary",
        title: t("main.sidebar.summary"),
        toggleHamburger: true
      },
      {
        show: company.menu.companyData,
        routeName: "report.company-data",
        icon: "company-report",
        title: t("main.sidebar.companyReport"),
        toggleHamburger: true
      },
      {
        show: company.menu.financialReportSummary,
        routeName: "report.financial-report-summary",
        icon: "financial-reports",
        title: t("main.sidebar.financialReportSummary"),
        toggleHamburger: true
      },
      {
        show: company.menu.financialReport,
        routeName: "report.financial-report",
        icon: "reports",
        title: t("main.sidebar.financialReport"),
        toggleHamburger: true
      },
      {
        show: company.menu.commonRatings,
        routeName: "report.common-ratings",
        icon: "common-ratings",
        title: t("main.sidebar.commonRatings"),
        toggleHamburger: true
      },
      {
        show: company.menu.negativeInfo,
        routeName: "report.negative-info",
        icon: "negative-info",
        title: t("main.sidebar.negativeInformations"),
        style: { color: company.negativeColor },
        toggleHamburger: true
      },
      {
        show: company.menu.positiveInfo,
        routeName: "report.positive-info",
        icon: "positive-info",
        title: t("main.sidebar.positiveInformations"),
        class: "svg",
        style: { color: company.positiveColor },
        toggleHamburger: true
      },
      { 
        show: company.menu.connections,
        routeName: "report.connections",
        icon: "connections",
        title: t("main.sidebar.connectionNetwork")
      },
      {
        show: company.menu.connectionList,
        routeName: "report.connection-list",
        icon: "switch",
        title: t("main.sidebar.connectionList"),
        toggleHamburger: true
      },
      {
        show: company.menu.timeline,
        routeName: "report.timeline",
        icon: "timeline",
        title: t("main.sidebar.timeline"),
        toggleHamburger: true
      },
      {
        show: company.menu.marketing,
        routeName: "report.marketing",
        icon: "marketing",
        title: t("main.sidebar.marketing"),
        toggleHamburger: true
      },
      {
        show: company.menu.sector,
        routeName: "report.sector",
        icon: "benefit-stat",
        title: t("main.sidebar.departmentIndicators"),
        toggleHamburger: true
      },
      {
        show: company.menu.finalOwner,
        routeName: "report.final-owner",
        icon: "person",
        title: t("main.sidebar.finalOwner"),
        toggleHamburger: true
      },
      {
        show: company.menu.im,
        routeName: "report.im",
        icon: "kim",
        title: t("main.sidebar.KIMCompanyInfo"),
        toggleHamburger: true
      }
    ].filter(x => x.show);
  });

  const toggleComparison = (event) => {
    op.value.toggle(event);
  };

  useEventBus<boolean>("showOnlyValid").on(x => {
    showOnlyValid.value = x;
  });  

  onClickOutside(clickoutside, event => hide());

  function toggleHamburger() {
    toggle(isHamburgerActive);
  }

  function addToComparison() {
    comparison.addCompany({
      name: company.name,
      id: company.id,
    });
    comparisonCompanies.value = comparison.companies;
  }

  function removeFromComparison(index) {
    comparison.deleteCompany(index);
    comparisonCompanies.value = comparison.companies;
  }

  function removeAll() {
    comparison.emptyAll();
    comparisonCompanies.value = comparison.companies;
  }

  function hide() {
    isHamburgerActive.value = false;
  }

  async function toggleMonitoring() {
    const data = await http.get("/api/monitoring/" + company.tax8 + "/toggle");
    toast(data.notification, data.notificationColor);    
    company.monitoring = data.state;
  }

  async function load() {
    const data = await http.get(`/api/report/${route.params.id}/header`);
    company.id = data.id;
    company.name = data.name;
    company.tax8 = data.tax8;
    company.menu = data.menu;
    company.status = data.status;
    company.risk = data.risk;
    company.type = data.type;
    company.monitoring = data.monitoring;
    company.positiveColor = data.positiveColor;
    company.negativeColor = data.negativeColor;
  }

  function downloadUrl() {
    return `/report/${route.params.id}/print`;
  }

  onMounted(async () => {
    loading.value = true;
    await load();
    loading.value = false;
  });

  watch(
    () => route.params.id,
    load
  );
</script>

<style lang="scss" scoped>
  .sidebar {
    & .company-info {
      position: fixed;
      top: $height-nav;
      z-index: 900;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
      height: $height-company-info;
      padding: 0 $padding-nav;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 8px 0px;
      background-color: white;

      @include media-breakpoint-down(lg) {
        top: $height-nav + 3.5rem;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;

        & .company-name {
          margin: 0 1rem 0 0;
          overflow: hidden;
          color: $color-font-primary;
          font-weight: 500;
          font-size: 2rem;
          text-overflow: ellipsis;

          @include media-breakpoint-down(lg) {
            margin: 0 1rem;
          }
        }

        & .company-status {
          display: flex;
          align-items: center;
          margin-right: 1rem;

          @include media-breakpoint-down(lg) {
            margin-right: 0.5rem;
          }

          & .icon {
            display: flex;
            margin-right: 0.5rem;
            font-size: 2.5rem;

            &--active {
              &::after {
                color: white;
                font-size: 2rem;
                font-family: $font-icon;
                content: "\e906";
              }
            }
          }

          & .status {
            color: $color-font-primary;
            font-weight: 400;
            font-size: 1.4rem;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }

        & .company-rating {
          display: flex;
          align-items: center;
          margin-right: 1rem;

          & .rating {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.2rem;
            min-width: 2.2rem;
            height: 2.2rem;
            min-height: 2.2rem;
            margin-right: 0.5rem;
            padding-right: 0.1rem;
            color: white;
            font-weight: 700;
            font-size: 1.3rem;
            font-family: $font-monospace;
            line-height: 1;
            border-radius: 50%;

            &--active {
              background-color: $color-green;
            }
          }

          & .status {
            color: $color-font-primary;
            font-weight: 400;
            font-size: 1.4rem;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }

      &-controls {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .sidebar-content {
      position: fixed;
      top: $height-nav + $height-company-info;
      bottom: 0;
      z-index: $z-index-sidebar;
      display: flex;
      flex-direction: column;
      width: $width-sidebar-desktop;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $color-sidebar-bg;
      transition: width 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

      &::-webkit-scrollbar {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        &.no-sidebar {
          width: $width-sidebar-mobile;

          &.show {
            width: $width-sidebar-desktop;
            box-shadow: $box-shadow-sidebar;

            & a {
              &.active {
                border-right: 3px solid $color-primary;
              }
            }
          }
        }
      }

      @include media-breakpoint-down(lg) {
        top: $height-nav + $height-company-info + 3.5rem;
        width: $width-sidebar-mobile;

        &.show {
          width: $width-sidebar-desktop;
          box-shadow: $box-shadow-sidebar;

          & a {
            &.active {
              border-right: 3px solid $color-primary;
            }
          }
        }
      }

      & a {
        display: flex;
        align-items: center;
        margin-left: $padding-nav;
        padding: 1rem;
        color: $color-font-primary;
        font-weight: 500;
        font-size: 1.4rem;
        white-space: nowrap;
        text-decoration: none;
        border-right: 3px solid transparent;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-right 0.2s ease-in-out;

        &.active {
          color: $color-primary;
          background-color: white;
          cursor: pointer;
          opacity: 1;

          & .icon {
            color: $color-primary;
          }
        }

        &:hover {
          color: $color-primary;
          background-color: white;
          cursor: pointer;
          opacity: 1;

          & .icon {
            color: $color-primary;
          }
        }


        & .icon {
          margin-right: 0.5rem;
          font-size: 2rem;
          transition: color 0.2s ease-in-out;
        }

        &.svg {
          & svg {
            width: 2rem;
            min-width: 2rem;
            height: 2rem;
            min-height: 2rem;
            margin-right: 0.5rem;
          }
        }

        & span {
          line-height: 1;
        }
      }
    }

    .monitoring-dropdown {
      & button {
        padding: 0 !important;
      }

      & .company-added {
        display: inline;
        color: $color-primary;
        font-size: 2.6rem;
        cursor: pointer;
      }
    }

    .icon-button {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      font-size: 1.4rem;
      cursor: pointer;

      & .icon {
        display: inline;
        color: $color-primary;
        font-size: 2.6rem;
      }

      & .label {
        margin-left: 0.5rem;
      }
    }

    .download {
      color: $color-font-primary;
      text-decoration: none;
    }
  }

  .comparison-dowpdown-content {
    width: 30rem;
  }
</style>
